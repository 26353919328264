import { PermissionCheckerService } from 'abp-ng2-module';
import {
    Injector,
    ElementRef,
    Component,
    OnInit,
    ViewEncapsulation,
    Inject,
    Renderer2,
    AfterViewInit,
} from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AppMenu } from './app-menu';
import { AppNavigationService } from './app-navigation.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { MenuOptions } from '@metronic/app/core/_base/layout/directives/menu.directive';
import { FormattedStringValueExtracter } from '@shared/helpers/FormattedStringValueExtracter';
import * as objectPath from 'object-path';

@Component({
    templateUrl: './side-bar-menu.component.html',
    selector: 'side-bar-menu',
    styles: [
        `.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-open>.menu-heading .menu-text, .aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-highlighted>.menu-link .menu-text {
            color: #6aa0ca;
        }`,
        `.disable-item {
                opacity: 40%;
                pointer-events: none;
                cursor: default;
        }`],
    encapsulation: ViewEncapsulation.None,
})
export class SideBarMenuComponent extends AppComponentBase
    implements OnInit, AfterViewInit {
    menu: AppMenu = null;

    currentRouteUrl = '';
    insideTm: any;
    outsideTm: any;

    menuOptions: MenuOptions = {
        submenu: {
            desktop: {
                default: 'dropdown',
            },
            tablet: 'accordion',
            mobile: 'accordion',
        },

        accordion: {
            expandAll: false,
        }
    };

    constructor(
        injector: Injector,
        private el: ElementRef,
        private router: Router,
        public permission: PermissionCheckerService,
        private _appNavigationService: AppNavigationService,
        private render: Renderer2
    ) {
        super(injector);
    }

    ngOnInit() {
        if (this.isPostSignupIncomplete()) {
            this.retrieveMenu(true);
        }
        else if (this.isPostSignupError()) {
            this.message.error(this.l("Login_Info_Error"));
        }
        else if (!this.isSignupSubdomain()) {
            this.retrieveMenu();
        }
        else {
            this.menu = undefined;
        }

        this.subMainMenuUpdate();
    }

    isSignupSubdomain(): boolean {
        if (this.appSession.tenant === undefined) {
            return false;
        }
        else {
            return this.appSession.tenant.tenancyName.indexOf('signup') >= 0 || window.location.origin.split('.')[0].indexOf("signup") >= 0;
        }
    }

    applyGlowToParentMenu(item): boolean {
        if (item.items.length > 0) {
            return item.items.some(x => x.applyGlow);
        }

        return false;
    }

    private retrieveMenu(postSignup: boolean = false) {
        let that = this;
        this.loadingStateService.isLoading = true;
        this._appNavigationService.getMenu(postSignup).then(value => {
            if (this.appSession.entityMaintenanceChanged) {
                this.message.info(this.l("CompletedEntityMaintenance"), this.l("PostSignupProcess"));
            }

            if (!this.appSession.completedClientMaintenance || !this.appSession.completedEntityMaintenance) {
                this.notify.info(this.l("IncompleteData"));
            }
            
            that.menu = value;
            that.currentRouteUrl = that.router.url.split(/[?#]/)[0];

            that.router.events
                .pipe(filter((event) => event instanceof NavigationEnd))
                .subscribe(
                    (event) =>
                        (that.currentRouteUrl = that.router.url.split(/[?#]/)[0])
                );

            that.loadingStateService.isLoading = false;
        });
    }

    ngAfterViewInit(): void {
        this.scrollToCurrentMenuElement();
    }

    ngOnDestroy() {
        this.unSubMainMenuUpdate();
    }

    showMenuItem(menuItem): boolean {
        return this._appNavigationService.showMenuItem(menuItem);
    }

    isMenuItemIsActive(item): boolean {
        if (item.items.length) {
            return this.isMenuRootItemIsActive(item);
        }

        if (!item.route) {
            return false;
        }

        let urlTree = this.router.parseUrl(
            this.currentRouteUrl.replace(/\/$/, '')
        );
        let urlString =
            '/' +
            urlTree.root.children.primary.segments
                .map((segment) => segment.path)
                .join('/');
        let exactMatch = urlString === item.route.replace(/\/$/, '');

        // Disregard select and approve payments when checking if menu is active in the enclosed if statement
        const notSelectAndApprovePayments = !urlString.includes("approve-payments") && !urlString.includes("select-payments");

        // This should allow for highlighting the menu item when going into item detail
        if (!exactMatch && notSelectAndApprovePayments) {
            let splitURLString = urlString.split("/");
            let retrievedKebabedNameFromURL = this.convertPascalCaseToKebabCase(splitURLString[splitURLString.length - 1]);

            let splitItemRoute = item.route.split("/");
            let retrievedNameFromItem = splitItemRoute[splitItemRoute.length - 1];

            if (retrievedKebabedNameFromURL === retrievedNameFromItem) {
                exactMatch = true;
            }
        }

        if (!exactMatch && item.routeTemplates) {
            for (let i = 0; i < item.routeTemplates.length; i++) {
                let result = new FormattedStringValueExtracter().Extract(
                    urlString,
                    item.routeTemplates[i]
                );
                if (result.IsMatch) {
                    return true;
                }
            }
        }
        return exactMatch;
    }

    isMenuRootItemIsActive(item): boolean {
        let result = false;

        for (const subItem of item.items) {
            result = this.isMenuItemIsActive(subItem);
            if (result) {
                return true;
            }
        }

        return false;
    }

    /**
  * Use for fixed left aside menu, to show menu on mouseenter event.
  * @param e Event
  */
    mouseEnter(e: Event) {
        // check if the left aside menu is fixed
        if (document.body.classList.contains('aside-fixed')) {
            if (this.outsideTm) {
                clearTimeout(this.outsideTm);
                this.outsideTm = null;
            }

            this.insideTm = setTimeout(() => {
                // if the left aside menu is minimized
                if (document.body.classList.contains('aside-minimize') && KTUtil.isInResponsiveRange('desktop')) {
                    // show the left aside menu
                    this.render.removeClass(document.body, 'aside-minimize');
                    this.render.addClass(document.body, 'aside-minimize-hover');
                }
            }, 50);
        }
    }

    /**
     * Use for fixed left aside menu, to show menu on mouseenter event.
     * @param e Event
     */
    mouseLeave(e: Event) {
        if (document.body.classList.contains('aside-fixed')) {
            if (this.insideTm) {
                clearTimeout(this.insideTm);
                this.insideTm = null;
            }

            this.outsideTm = setTimeout(() => {
                // if the left aside menu is expand
                if (document.body.classList.contains('aside-minimize-hover') && KTUtil.isInResponsiveRange('desktop')) {
                    // hide back the left aside menu
                    this.render.removeClass(document.body, 'aside-minimize-hover');
                    this.render.addClass(document.body, 'aside-minimize');
                }
            }, 100);
        }
    }

    scrollToCurrentMenuElement(): void {
        const path = location.pathname;
        const menuItem = document.querySelector('a[href=\'' + path + '\']');
        if (menuItem) {
            menuItem.scrollIntoView({ block: 'center' });
        }
    }

    getItemAttrSubmenuToggle(item) {
        let toggle = 'hover';
        if (objectPath.get(item, 'toggle') === 'click') {
            toggle = 'click';
        } else if (objectPath.get(item, 'submenu.type') === 'tabs') {
            toggle = 'tabs';
        } else {
            // submenu toggle default to 'hover'
        }

        return toggle;
    }

    getItemCssClasses(item) {
        let classes = 'menu-item';
        
        if (objectPath.get(item, 'submenu')) {
            classes += ' menu-item-submenu';
        }

        if (!item.items && this.isMenuItemIsActive(item)) {
            classes += ' menu-item-active menu-item-here';
        }

        if ((item.permissionName === "Pages.ApprovePayments" || item.permissionName === "Pages.SelectPayments") && item.items.length > 0) {
            classes += ' menu-item-open';
        }

        if (item.items && this.isMenuItemIsActive(item)) {
            classes += ' menu-highlighted menu-item-here';
        }

        // custom class for menu item
        const customClass = objectPath.get(item, 'custom-class');
        if (customClass) {
            classes += ' ' + customClass;
        }

        if (objectPath.get(item, 'icon-only')) {
            classes += ' menu-item-icon-only';
        }

        classes += ' ' + this.convertPascalCaseToKebabCase(item.name);

        return classes;
    }

    subMainMenuUpdate() {
        abp.event.on('app.updateMainMenu', this.updateMainMenu);
    }

    unSubMainMenuUpdate() {
        abp.event.off('app.updateMainMenu', this.updateMainMenu);
    }

    updateMainMenu = () => {
        if (this.isPostSignupIncomplete()) {
            this.retrieveMenu(true);
        }
        else {
            this.retrieveMenu();
        }
    }
}
